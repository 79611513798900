import { z } from 'zod';

export const contractOptions = ['Permanent', 'Contract', 'Part-time', 'Freelance'] as const;

export const jobFormSchema = z.object({
  company: z.object({
    id: z.string().nullish(),
    name: z.string().min(1, { message: 'Required' }),
  }),
  location: z.string().max(100, "Can't be longer than 100 characters").nullish(),
  overview: z
    .string()
    .min(1, { message: 'Required' })
    .max(3000, "Can't be longer than 3000 characters"),
  culture: z
    .string()
    .min(1, { message: 'Required' })
    .max(2500, "Can't be longer than 3000 characters"),
  benefits: z.string().max(1000, "Can't be longer than 1000 characters").nullish(),
  title: z.string().min(1, { message: 'Required' }),
  salary: z.string().max(100, "Can't be longer than 100 characters.").nullish(),
  contract: z.enum(contractOptions),
  responsibilities: z
    .string()
    .min(1, { message: 'Required' })
    .max(3000, "Can't be longer than 3000 characters"),
  mustHaves: z
    .string()
    .min(1, { message: 'Required' })
    .max(3000, "Can't be longer than 3000 characters"),
});

export type JobFormValues = z.infer<typeof jobFormSchema>;
