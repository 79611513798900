import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { useLazyQuery } from '@apollo/client';
import { PageTitle } from '../../components/PageTitle';
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { CompanyAutocompleteField, AutocompleteField } from '../../components/Autocomplete';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextAreaField } from 'src/components/TextArea';
import { FormTipsCard, SelectField, TextField, FormError, DialogLayout } from 'src/components';
import { Button } from '../../components/Button';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  CreateJob,
  CreateJobVariables,
  ExtractJobFileMutation,
  ExtractJobFileMutationVariables,
  ExtractJobUrlMutation,
  ExtractJobUrlMutationVariables,
  GetCompany,
  GetCompanyVariables,
  GetJobTitles,
  GetJobTitlesVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from 'types/graphql';
import { toast } from '@redwoodjs/web//toast';
import { navigate, routes } from '@redwoodjs/router';
import { useDialog, useFileDragDrop, usePageClasses, useTrackPageView } from 'src/hooks';
import { useOnboardingStore } from '../../services';
import { Spinner } from '../../components/Spinner';
import {
  AUTO_FILL_JOB_MUST_HAVES_MUTATION,
  AUTO_FILL_JOB_ROLE_AND_RESPONSIBILITIES_MUTATION,
  CREATE_JOB_MUTATION,
  EXTRACT_JOB_FILE_MUTATION,
  EXTRACT_JOB_URL_MUTATION,
} from '../../graphql/mutations';
import { GET_COMPANY_QUERY, GET_JOB_TITLES_QUERY, GET_ME_QUERY } from '../../graphql/queries';
import { contractOptions, jobFormSchema, JobFormValues } from '../../lib/formSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { CampaignOnboardingWizard } from '../../components/CampaignOnboardingWizard';
import { JOB_CAMPAIGN_CONFIGURABLE_DOCUMENTS } from '../../lib/document';
import { classNames } from 'src/lib';
import { IconButton } from 'src/components/IconButton';
import { AnimatePresence, motion } from 'framer-motion';
import { Form } from 'src/components/Form/Form';
import { SmartDataUploader } from 'src/components';
import { convertFileToBase64 } from '../../lib/file';

const helpSections: Record<
  Extract<
    keyof JobFormValues,
    'overview' | 'culture' | 'benefits' | 'responsibilities' | 'mustHaves' | 'salary'
  >,
  string[]
> = {
  overview: [
    'Background / History',
    'Size',
    'Purpose',
    'Goals',
    'Achievements',
    'Awards / Recognitions',
    'Unique Selling Points',
  ],
  culture: [
    'Values e.g. integrity',
    'Work Environment e.g flexible hours',
    'Perks e.g. monthly team socials',
    'Career Development e.g. training',
  ],
  benefits: [
    'Holiday allowance',
    'Pension',
    'Healthcare',
    'Bonuses',
    'Flexibility',
    'Parental leave',
  ],
  responsibilities: ['Who will they report to?', 'Key responsibilities', 'Specific projects/tasks'],
  mustHaves: ['Education', 'Experience', 'Key skills', 'Personal attributes'],
  salary: ['Salary range', 'Commission', 'Equity'],
};

type AutoFillOverlayProps = {
  children: ReactNode;
  onAutoFill: () => void;
  canAutoFill?: boolean;
};

/**
 *
 * @param param0
 * @returns A wrapper component that renders the children and an optional button to trigger the onAutoFill handler
 * @description it is to be used as a wrapper for the fields that can be auto-filled, such as responsibilities and must haves(TextArea)
 */
const AutoFillOverlay: FC<AutoFillOverlayProps> = ({
  children,
  onAutoFill,
  canAutoFill = false,
}) => {
  return (
    <div className="relative flex h-full w-full flex-1">
      {children}
      <AnimatePresence>
        {canAutoFill && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute right-2 top-8"
          >
            <IconButton
              size="small"
              Icon={SparklesIcon}
              tooltipText="Not sure what to write? Click to auto-fill based on your answers to previous fields."
              onClick={onAutoFill}
              variant="generate"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const NewJobPage = () => {
  useTrackPageView();
  const _ = usePageClasses('bg-white');
  const { show } = useDialog({
    onClose: () => useOnboardingStore.setState({ showWelcomeDialog: false }),
  });
  const {
    data: getMeData,
    loading: getMeLoading,
    error: getMeError,
  } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: {
      isAdmin: false,
    },
  });

  const [getCompany, { loading }] = useLazyQuery<GetCompany, GetCompanyVariables>(
    GET_COMPANY_QUERY
  );
  const [focusedField, setFocusedField] = useState<
    null | [keyof typeof helpSections | null, 'left' | 'right']
  >(null);
  const [focused, side] = focusedField ?? [null, 'left'];

  const formMethods = useForm<JobFormValues>({
    defaultValues: { contract: 'Permanent' },
    resolver: zodResolver(jobFormSchema),
  });
  const { data, error: getJobTitlesError } = useQuery<GetJobTitles, GetJobTitlesVariables>(
    GET_JOB_TITLES_QUERY
  );

  const [autofillJob, { loading: autoFillJobLoading }] = useMutation<
    ExtractJobFileMutation,
    ExtractJobFileMutationVariables
  >(EXTRACT_JOB_FILE_MUTATION, {
    /**
     * Autofill the form with the data from the scanned job description
     * If the company name, overview or culture fields are already filled, ask the user if they want to override them
     * Otherwise, autofill the form with the data from the scanned job description
     */
    onCompleted: (data) => {
      const formValues = formMethods.getValues();
      if (!!formValues?.company?.name || !!formValues.overview || !!formValues.culture) {
        const onConfirm = (result: boolean) => {
          if (result) {
            formMethods.reset({
              location: data.extractJobFile.location ?? formValues.location,
              overview: data.extractJobFile.companyOverview ?? formValues.overview,
              culture: data.extractJobFile.companyCulture ?? formValues.culture,
              benefits: data.extractJobFile.companyBenefits ?? formValues.benefits,
              title: data.extractJobFile.jobTitle ?? formValues.title,
              contract: (data.extractJobFile.contract ??
                formValues.contract) as JobFormValues['contract'],
              responsibilities:
                data.extractJobFile.jobResponsibilities ?? formValues.responsibilities,
              mustHaves: data.extractJobFile.jobRequirements ?? formValues.mustHaves,
              salary: data.extractJobFile.salary ?? formValues.salary,
              company: {
                name: data.extractJobFile.companyName ?? formValues.company.name,
                id: null ?? formValues.company?.id,
              },
            });
          } else {
            formMethods.reset({
              ...formValues,
              benefits: data.extractJobFile.companyBenefits ?? formValues.benefits,
              title: data.extractJobFile.jobTitle ?? formValues.title,
              contract: (data.extractJobFile.contract ??
                formValues.contract) as JobFormValues['contract'],
              responsibilities:
                data.extractJobFile.jobResponsibilities ?? formValues.responsibilities,
              mustHaves: data.extractJobFile.jobRequirements ?? formValues.mustHaves,
              salary: data.extractJobFile.salary ?? formValues.salary,
            });
          }
        };
        show(<ConfirmCompanyOverrideDialog onConfirm={onConfirm} />);
      } else {
        formMethods.reset({
          location: data.extractJobFile.location ?? formValues.location,
          overview: data.extractJobFile.companyOverview ?? formValues.overview,
          culture: data.extractJobFile.companyCulture ?? formValues.culture,
          benefits: data.extractJobFile.companyBenefits ?? formValues.benefits,
          title: data.extractJobFile.jobTitle ?? formValues.title,
          contract: (data.extractJobFile.contract ??
            formValues.contract) as JobFormValues['contract'],
          responsibilities: data.extractJobFile.jobResponsibilities ?? formValues.responsibilities,
          mustHaves: data.extractJobFile.jobRequirements ?? formValues.mustHaves,
          salary: data.extractJobFile.salary ?? formValues.salary,
          company: {
            name: data.extractJobFile.companyName ?? formValues.company.name,
            id: null ?? formValues.company?.id,
          },
        });
      }
    },
  });

  const [
    autoFillJobRolesAndResponsibilities,
    { loading: autoFillJobRolesAndResponsibilitiesLoading },
  ] = useMutation(AUTO_FILL_JOB_ROLE_AND_RESPONSIBILITIES_MUTATION, {
    onCompleted: (data) => {
      formMethods.setValue(
        'responsibilities',
        data.autoFillJobRoleAndResponsibilities.roleAndResponsibilities
      );
    },
  });

  const [autoFillJobMustHaves, { loading: autoFillJobMustHavesLoading }] = useMutation(
    AUTO_FILL_JOB_MUST_HAVES_MUTATION,
    {
      onCompleted: (data) => {
        formMethods.reset({
          ...formMethods.getValues(),
          mustHaves: data.autoFillJobMustHaves.mustHaves,
        });
      },
    }
  );

  const [extractJobDetails, { loading: extractJobDetailsLoading }] = useMutation<
    ExtractJobUrlMutation,
    ExtractJobUrlMutationVariables
  >(EXTRACT_JOB_URL_MUTATION, {
    onCompleted: (data) => {
      formMethods.reset({
        ...formMethods.getValues(),
        title: data.extractJobUrl.jobTitle ?? '',
        contract:
          (data.extractJobUrl.contract as 'Permanent' | 'Contract' | 'Part-time' | 'Freelance') ??
          ('Permanent' as 'Permanent' | 'Contract' | 'Part-time' | 'Freelance'),
        responsibilities: data.extractJobUrl.jobResponsibilities ?? '',
        mustHaves: data.extractJobUrl.jobRequirements ?? '',
        salary: data.extractJobUrl.salary ?? '',
        company: {
          name: data.extractJobUrl.companyName ?? '',
          id: null,
        },
        location: data.extractJobUrl.location ?? '',
        overview: data.extractJobUrl.companyOverview ?? '',
        culture: data.extractJobUrl.companyCulture ?? '',
        benefits: data.extractJobUrl.companyBenefits ?? '',
      });
    },
  });

  const [mutate, { loading: mutationLoading, error: mutationError }] = useMutation<
    CreateJob,
    CreateJobVariables
  >(CREATE_JOB_MUTATION);

  const queryError = getMeError || getJobTitlesError;
  if (queryError) {
    throw queryError;
  }

  const companyValue = formMethods.watch('company');
  const titleValue = formMethods.watch('title');
  const mustHavesValue = formMethods.watch('mustHaves');
  const roleAndResponsibilitiesValue = formMethods.watch('responsibilities');

  const generateRolesAndResponsibilitiesHandler = async () => {
    const { title, salary, contract, culture, overview } = formMethods.getValues();

    await autoFillJobRolesAndResponsibilities({
      variables: {
        input: {
          jobTitle: title,
          companyCulture: culture,
          companyOverview: overview,
          contractType: contract,
          salary: salary,
        },
      },
    });
  };

  const generateJobMustHavesHandler = async () => {
    const { title, salary, contract, culture, overview } = formMethods.getValues();

    await autoFillJobMustHaves({
      variables: {
        input: {
          jobTitle: title,
          companyCulture: culture,
          companyOverview: overview,
          contractType: contract,
          salary: salary,
        },
      },
    });
  };

  /**
   * Fetch company details and populate fields on selecting a new company
   */
  useEffect(() => {
    (async () => {
      if (companyValue?.id) {
        const { data } = await getCompany({
          variables: { id: companyValue.id },
        });
        const company = data?.company;
        if (!company) {
          return;
        }

        formMethods.reset((values) => ({
          ...values,
          culture: company.culture,
          benefits: company.benefits,
          location: company.location,
          overview: company.overview,
        }));
      }
    })();
  }, [companyValue?.id]);

  const onSubmit: SubmitHandler<JobFormValues> = async (values) => {
    const result = await mutate({
      variables: {
        input: {
          contractType: values.contract,
          mustHaves: values.mustHaves,
          roleAndResponsibilities: values.responsibilities,
          salary: values.salary,
          title: values.title,
          company: {
            id: values.company?.id,
            benefits: values.benefits,
            culture: values.culture,
            location: values.location,
            name: values.company.name,
            overview: values.overview,
          },
        },
      },
    });
    if (result.data) {
      navigate(routes.job({ jobId: result.data.createJob.id, initial: true }));
      toast.success('Job created!');
    }
  };

  const handleUploadFile = async (file: File) => {
    const base64Contents = await convertFileToBase64(file);
    toast.promise(
      autofillJob({
        variables: {
          input: {
            filename: file.name,
            base64Contents,
          },
        },
      }),
      {
        error: 'Unable to scan this file, please try another one',
        loading: 'Loading',
        success: 'Success',
      }
    );
  };

  const { getRootProps, isDragActive } = useFileDragDrop(handleUploadFile);

  const onUrlScrape = (url: string) => {
    toast.promise(
      extractJobDetails({
        variables: { url },
      }),
      {
        loading: 'Loading',
        success: 'Success',
        error: 'Unable to scan URL, please try a different link',
      },
      {}
    );
  };

  const selectOptions = contractOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const inputsLoading = extractJobDetailsLoading || autoFillJobLoading || loading;

  if (mutationLoading || getMeLoading) {
    return <Spinner />;
  }

  if (getMeData?.me?.jobCampaignOnboardingStatus === 'PENDING') {
    return (
      <CampaignOnboardingWizard
        configurableDocuments={JOB_CAMPAIGN_CONFIGURABLE_DOCUMENTS}
        onboardingType="JOB"
      />
    );
  }

  return (
    <div className="flex flex-grow pb-12">
      <MetaTags title="New job" description="Create a Campaign" />
      <div className="min-w-[250px] flex-1">
        {side === 'left' && (
          <FormTipsCard animateEntry tips={focused ? helpSections[focused] : []} />
        )}
      </div>
      <div className="flex w-full max-w-5xl flex-col ">
        <div className="flex w-full max-w-5xl items-center justify-between pb-3 pt-6">
          <div className="">
            <PageTitle Icon={PencilIcon} text="Job Description" />
            <p className="pt-1 text-sm text-text-medium">
              💡 Tip: Drag and drop a file straight into the form to auto-fill the job details!
            </p>
          </div>

          <SmartDataUploader onFileUpload={handleUploadFile} onUrlScrape={onUrlScrape} />
        </div>

        <div
          className={classNames(
            'flex w-full max-w-5xl flex-grow border border-dashed border-transparent transition-all duration-75 ease-in',
            isDragActive && 'rounded-2xl border-generate-light bg-blue-50'
          )}
          {...getRootProps()}
        >
          <Form<JobFormValues>
            formMethods={formMethods}
            className={classNames(
              'flex flex-grow gap-x-9 transition-all duration-75 ease-in sm:flex-wrap',
              isDragActive && 'scale-[0.97]'
            )}
            onSubmit={onSubmit}
          >
            <div className="flex flex-1 flex-col gap-y-2">
              <CompanyAutocompleteField
                placeholder="Select or add a company"
                onBlur={() => setFocusedField([null, 'left'])}
                label="Company Name"
                name="company"
                schema={jobFormSchema}
                disabled={inputsLoading}
              />
              <TextField
                label="Location"
                onBlur={() => setFocusedField([null, 'left'])}
                name="location"
                placeholder="e.g North London | Hybrid"
                loading={inputsLoading}
                schema={jobFormSchema}
              />
              <TextAreaField
                onFocus={() => setFocusedField(['overview', 'left'])}
                label="Company Overview"
                name="overview"
                placeholder="e.g. global leading design and communications agency, 2,000 employees across 29 studios…"
                loading={inputsLoading}
                schema={jobFormSchema}
              />
              <TextAreaField
                onFocus={() => setFocusedField(['culture', 'left'])}
                label="Company Culture"
                name="culture"
                placeholder="e.g. growth-minded and ambitious, engaging and supportive environment..."
                loading={inputsLoading}
                schema={jobFormSchema}
              />
              <TextAreaField
                onFocus={() => setFocusedField(['benefits', 'left'])}
                label="Benefits"
                name="benefits"
                placeholder="e.g. up to 2 days remote working, health insurance…"
                loading={inputsLoading}
                schema={jobFormSchema}
              />
            </div>
            <div className="flex flex-1 flex-col gap-y-4">
              <AutocompleteField
                label="Job Title"
                name="title"
                onBlur={() => setFocusedField([null, 'right'])}
                placeholder="Select or add a job title"
                options={[...new Set(data?.jobs?.nodes.map((jobTitle) => jobTitle.title))]}
                schema={jobFormSchema}
                disabled={inputsLoading}
              />
              <div className="flex gap-x-3">
                <TextField
                  label="Remuneration"
                  name="salary"
                  onFocus={() => setFocusedField(['salary', 'right'])}
                  onBlur={() => setFocusedField([null, 'right'])}
                  placeholder="e.g £30k-35k + Commission"
                  loading={inputsLoading}
                />
                <SelectField
                  label="Contract Type"
                  name="contract"
                  onBlur={() => setFocusedField([null, 'right'])}
                  placeholder="Permanent"
                  options={selectOptions}
                  loading={inputsLoading}
                />
              </div>
              <AutoFillOverlay
                canAutoFill={Boolean(
                  !roleAndResponsibilitiesValue &&
                    titleValue &&
                    !autoFillJobRolesAndResponsibilitiesLoading
                )}
                onAutoFill={generateRolesAndResponsibilitiesHandler}
              >
                <TextAreaField
                  onFocus={() => setFocusedField(['responsibilities', 'right'])}
                  label="Role and Responsibilities"
                  name="responsibilities"
                  placeholder="e.g. reporting to the project lead, responsible for building best in class applications..."
                  /**
                   * Extra padding for autocomplete button
                   */
                  inputClassName="pr-11"
                  loading={inputsLoading || autoFillJobRolesAndResponsibilitiesLoading}
                  schema={jobFormSchema}
                />
              </AutoFillOverlay>
              <AutoFillOverlay
                canAutoFill={Boolean(!mustHavesValue && titleValue && !autoFillJobMustHavesLoading)}
                onAutoFill={generateJobMustHavesHandler}
              >
                <TextAreaField
                  onFocus={() => setFocusedField(['mustHaves', 'right'])}
                  label="Must Haves"
                  name="mustHaves"
                  placeholder="e.g. Practical experience building B2B iOS applications, advanced Vanilla JS an ECMAScript, self-starter..."
                  /**
                   * Extra padding for autocomplete button
                   */
                  inputClassName="pr-11"
                  loading={inputsLoading || autoFillJobMustHavesLoading}
                  schema={jobFormSchema}
                />
              </AutoFillOverlay>
              <FormError error={mutationError} />
              <Button
                type="submit"
                loading={mutationLoading}
                LeftIcon={SparklesIcon}
                onBlur={() => setFocusedField([null, 'right'])}
                text={mutationLoading ? 'Loading' : 'Generate'}
                size="mega"
              />
            </div>
          </Form>
        </div>
      </div>

      {/* Spacer column to keep body centered */}
      <div className="min-w-[250px] flex-1">
        {side === 'right' && <FormTipsCard tips={focused ? helpSections[focused] : []} />}
      </div>
    </div>
  );
};

const ConfirmCompanyOverrideDialog: FC<{
  onConfirm: (result: boolean) => void;
}> = ({ onConfirm }) => {
  const { close } = useDialog();
  return (
    <DialogLayout
      onClose={close}
      Icon={ExclamationTriangleIcon}
      title="Do you wish to update the company fields?"
      className="w-full max-w-xl gap-y-4"
    >
      <p className="text-text-medium">
        The form is already partially completed, do you wish to override the company fields?
      </p>
      <p className="text-text-medium">
        Company fields include: <i>Location, Overview, Culture</i>
      </p>

      <p className="text-text-medium">
        Select{' '}
        <i>
          <em>Yes</em>
        </i>{' '}
        to update the fields above wih the scan result, or{' '}
        <i>
          <em>No</em>
        </i>{' '}
        to keep the company details as they are while populating the remaining fields.
      </p>
      <div className="flex justify-center gap-x-4">
        <Button
          variant="outline"
          text="Yes"
          onClick={() => {
            onConfirm(true);
            close();
          }}
        />
        <Button
          variant="outline"
          text="No"
          onClick={() => {
            onConfirm(false);
            close();
          }}
        />
      </div>
    </DialogLayout>
  );
};

export default NewJobPage;
